import { React } from "react";
import { NavLink } from 'react-router-dom';
import "./Nav.scss";
import logo_scrive from "../../assets/img/Scrive_Logo_Light.png";
import logo_s from "../../assets/img/s_logo.png";

const Navbar = (props) => {
  return (
    <div className="nav-wrapper">
      <div>
        <img src={logo_scrive} alt={logo_scrive} className="logo"/>
        <img src={logo_s} alt={logo_scrive} className="logo_s"/>
      </div>
      <div>
  		<NavLink to={`/${props.status.toLowerCase()}`} className="nav-link" >
        {props.status}
  		</NavLink>
      </div>
  	</div>
  );
};

export default Navbar;
