import Nav from "../global/Navbar.js";
import pic from "../../assets/img/pic2.png";
import "./HomeApp.scss";

function Home() {
  const success = sessionStorage.getItem("success");
  console.log(success)

  return (
    <div className="home-body">
      <Nav status="Login" />
      <div className="home-container">
        <div className="text-box">
          <h1>Welcome to Scrive!</h1>
          <h2>Login to access my pages</h2>
        </div>
        <img src={pic} alt="pic-1" className="pic-1"/>
      </div>
    </div>
  );
}

export default Home;
