import "./Alert.scss";
import { React, useEffect, useState} from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


function Alert({setSuccessAlert, message}) {
  const [ alertMessage, setAlertMessage ] = useState("");

  useEffect(() => {
    setAlertMessage(message);
  }, []);

  return (
    <div className='success-container'>
        <div className='success-inner'>
           <CheckCircleIcon className="success" style={{transform: "scale(2.2)"}}/>
           <p className="success-msg">Your application was successful!</p>
           <p>{alertMessage}</p>
           <div className="ok-btn"onClick={() => setSuccessAlert(false)}>OK</div>
        </div>
    </div>
  );
}

export default Alert;
