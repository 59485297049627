import React, { useState } from 'react';
import axios from 'axios';
import "./Form.scss";
import countries from"./countries.js";
//import demo_data from '../../assets/files/demo_data.json'
let { REACT_APP_BACKEND_URL } = process.env;

// remove trailing slash
REACT_APP_BACKEND_URL = REACT_APP_BACKEND_URL.replace(/\/$/, '');

const { REACT_APP_API_URL} = process.env;

  const Form = ({navigateForm}) => {
    const [ errorAlert, setErrorAlert ] = useState(false);
    const [ validate, setValidate ] = useState(false);
    const [error, setError] = useState({
      fstname : 'This field cannot be empty.',
      sndname: 'This field cannot be empty.',
      email: 'This field cannot be empty.',
      mobile: 'This field cannot be empty.',
      address: 'This field cannot be empty.',
      zipcode: 'This field cannot be empty.',
      city: 'This field cannot be empty.',
      country: 'This field cannot be empty.',
      number: 'This field cannot be empty.',
      cname: 'This field cannot be empty.',
      signatory: 'This field cannot be empty.',
      sig_email: 'This field cannot be empty.',
      delivery: ''
    });
    const [form, setForm] = useState({
      fstname : '',
      sndname: '',
      email: '',
      mobile: '',
      address: '',
      zipcode: '',
      city: '',
      country: ' ',
      number: ' ',
      cname: ' ',
      signatory: '',
      sig_email: '',
      delivery: '',
      type: 'consumer',
      add: 'no'
      });

    const validateForm = () => {
      let valid = true;
      if (form.delivery==='') {
        setError({
           ...error,
          delivery: 'Select a delivery method before submitting.',
        });
      }
      if (form.type==='consumer') {
        Object.values(error).slice(0, 7).forEach(val => val.length > 0 && (valid = false));
      }
      if (form.add==='no' && form.type==='business') {
        Object.values(error).slice(0, 10).forEach(val => val.length > 0 && (valid = false));
      }
      if (form.add==='yes' && form.type==='business') {
        Object.values(error).forEach(val => val.length > 0 && (valid = false));
      }
      return valid;
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      let message = "";
      setForm({
         ...form,
        [name]: value,
      });
      switch (name) {
        case 'fstname':
        case 'sndname':
        case 'address':
        case 'zipcode':
        case 'city':
        case 'country':
        case 'cname':
        case 'number':
        case 'signatory':
          message =
            value.length < 1
              ? "This field cannot be empty."
              : '';
          break;
        case 'email':
        case 'sig_email':
          message =
            value.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)
              ? ''
              : 'Email address is not valid.';
          break;
        case 'mobile':
          message =
            value.match(/^[+][0-9]{6,12}$/)
              ? ''
              : 'Phone number is not valid.';
          break;
          default:
      }
      setError({
         ...error,
        [name]: message,
      });
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      let valid = validateForm();
      if (valid) {
        axios.post(`${REACT_APP_BACKEND_URL}/documents/demo`, form)
        .then(res => {
          if (res.status===200) {
            if (form.delivery==="api") {
              window.open(`${REACT_APP_API_URL}${res.data.parties[1].api_delivery_url}`);
            }
            navigateForm("callback", {data:res.data, delivery: form.delivery});
          }
        })
        .catch(error => {
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 4000);
        })
      } else {
        setValidate(true);
      }
    };

    const application_type = (
      <div>
          <div className="form-group switch">
            <div className="delivery-box type">
              <label htmlFor="type-1" className="radio-btn">
              <input
                name="type"
                id="type-1"
                type="radio"
                checked={form.type==="consumer"}
                value="consumer"
                onChange={handleChange}
               />
              <span className="method">Private</span>
              </label>
              <label htmlFor="type-2" className="radio-btn">
              <input
                name="type"
                id="type-2"
                type="radio"
                checked={form.type==="business"}
                value="business"
                onChange={handleChange}
               />
              <span className="method">Company</span>
              </label>
            </div>
            <p className="validation">{validate && error.type}</p>
          </div>
      </div>
    )

    const add_signatory = (
      <div>
        <div className="form-group">
          <label htmlFor="signatory">Company signatory</label>
          <input
            name="signatory"
            id="signatory"
            type="text"
            value={form.signatory}
            onChange={handleChange}
           />
        </div>
        <p className="validation">{validate && error.signatory}</p>
        <div className="form-group">
          <label htmlFor="sig_email">Signatory email</label>
          <input
            name="sig_email"
            id="sig_email"
            type="text"
            value={form.sig_email}
            onChange={handleChange}
           />
        </div>
        <p className="validation">{validate && error.sig_email}</p>
      </div>
    )

    const delivery_submit = (
      <div>
        <div className="form-group">
        <p>Delivery method for signing</p>
          <div className="delivery-box">
            <label htmlFor="delivery-1" className="radio-btn">
            <input
              name="delivery"
              id="delivery-1"
              type="radio"
              checked={form.delivery==="email"}
              value="email"
              onChange={handleChange}
             />
            <span className="method">Email</span>
            </label>
            <label htmlFor="delivery-2" className="radio-btn">
            <input
              name="delivery"
              id="delivery-2"
              type="radio"
              checked={form.delivery==="mobile"}
              value="mobile"
              onChange={handleChange}
             />
            <span className="method">SMS</span>
            </label>
            <label htmlFor="delivery-3" className="radio-btn">
            <input
              name="delivery"
              id="delivery-3"
              type="radio"
              checked={form.delivery==="api"}
              value="api"
              onChange={handleChange}
             />
            <span htmlFor="delivery-4" className="method">Sign directly</span>
            </label>
            <label className="radio-btn">
            <input
              name="delivery"
              id="delivery-4"
              type="radio"
              checked={form.delivery==="pad"}
              value="pad"
              onChange={handleChange}
             />
            <span className="method">Tablet</span>
            </label>
          </div>
          <p className="validation">{validate && error.delivery}</p>
        </div>
        <button type="submit" className="btn submit-btn">SEND APPLICATION</button>
      </div>
    )

    const personal_info = (
      <div>
        <div className="form-container first">
        <h2>Fill in your details</h2>
          <div className="form-group">
            <label htmlFor="fstname">First name</label>
            <input
              name="fstname"
              id="fstname"
              type="text"
              value={form.fstname}
              onChange={handleChange}
             />
          </div>
          <p className="validation">{validate && error.fstname}</p>
          <div className="form-group">
            <label htmlFor="sndname">Last name</label>
            <input
              name="sndname"
              id="sndname"
              type="text"
              value={form.sndname}
              onChange={handleChange}
             />
          </div>
          <p className="validation">{validate && error.sndname}</p>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              id="email"
              type="text"
              value={form.email}
              onChange={handleChange}
              autoComplete="off"
             />
          </div>
          <p className="validation">{validate && error.email}</p>
          <div className="form-group">
            <label htmlFor="mobile">Phone number</label>
            <input
              name="mobile"
              id="mobile"
              type="text"
              value={form.mobile}
              onChange={handleChange}
             />
          </div>
          <p className="validation">{validate && error.mobile}</p>

          <br/>

          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              name="address"
              id="address"
              type="text"
              value={form.address}
              onChange={handleChange}
              autoComplete="off"
             />
          </div>
          <p className="validation">{validate && error.address}</p>
          <div className="form-group">
            <label htmlFor="zipcode">Zip code</label>
            <input
              name="zipcode"
              id="zipcode"
              type="text"
              value={form.zipcode}
              onChange={handleChange}
             />
          </div>
          <p className="validation">{validate && error.zipcode}</p>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              name="city"
              id="city"
              type="text"
              value={form.city}
              onChange={handleChange}
             />
          </div>
          <p className="validation">{validate && error.city}</p>
        </div>
      </div>
    )

    const business = (
      <div className={"form-container media " + form.add}>
        <div className="form-group">
          <label htmlFor="cname">Company name</label>
          <input
            name="cname"
            id="cname"
            type="text"
            value={form.cname}
            onChange={handleChange}
           />
        </div>
        <p className="validation">{validate && error.cname}</p>
        <div className="form-group">
          <label htmlFor="number">Organisation number</label>
          <input
            name="number"
            id="number"
            type="text"
            value={form.number}
            onChange={handleChange}
           />
        </div>
        <p className="validation">{validate && error.number}</p>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <select id="country" name="country" className="form-control" value={form.country} onChange={handleChange} autoComplete="off">
            <option value="">Select an option</option>
            {countries.map((c) => <option key={c} value={c}>{c}</option>)}
          </select>
        </div>
        <p className="validation">{validate && error.country}</p>

        <div className="form-group">
          <p className="q-label">Do you need to add a signer on behalf of the company?</p>
          <div className="q-box">
            <label htmlFor="add-1" className="add-1">
            <input
              name="add"
              id="add-1"
              type="radio"
              checked={form.add==="yes"}
              value="yes"
              onChange={handleChange}
             />
            <span className="method">Yes</span>
            </label>
            <label htmlFor="add-2" className="add-2">
            <input
              name="add"
              id="add-2"
              type="radio"
              checked={form.add==="no"}
              value="no"
              onChange={handleChange}
             />
            <span className="method">No</span>
            </label>
          </div>
          <p className="validation">{validate && error.type}</p>
        </div>

        {form.add==="yes" && add_signatory}
        {delivery_submit}
      </div>
    )

    const consumer = (
      <div className="form-container media consumer">
        {delivery_submit}
      </div>
    )

    return (
      <form onSubmit={handleSubmit} className="form">
       {application_type}
       {personal_info}
       <div className="vl"></div>
       {form.type==="business" && business}
       {form.type==="consumer" && consumer}
       {errorAlert && <div className='alert-container'>
           <div className='alert-inner'>
              <p>Something went wrong</p>
              <p>Please try again</p>
           </div>
       </div>}
      </form>
    );
  };

export default Form;
