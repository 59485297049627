import React from 'react';
import axios from 'axios';
import "./Login.scss";
import icon_sv_bankid from "../../assets/img/BankID_logo.svg";
import icon_freja from "../../assets/img/Freja.png";
import icon_no_bankid from "../../assets/img/BankID_NO_logo.svg";
import icon_mitid from "../../assets/img/MitID_logo.svg";
import icon_idin from "../../assets/img/iDIN.svg";
import icon_itsme from "../../assets/img/ItsMe.svg";
import icon_verimi from "../../assets/img/Verimi.svg";
import icon_smsotp from "../../assets/img/PINbySMS.svg";
import icon_user from "../../assets/img/User_48px.svg";
import Nav from "../global/Navbar.js";
let { REACT_APP_BACKEND_URL } = process.env;

// remove trailing slash
REACT_APP_BACKEND_URL = REACT_APP_BACKEND_URL.replace(/\/$/, '');

const redirectUrl = `${window.location.protocol}//${window.location.host}/redirect`;

const Login = () => {
    const handleID = (provider) => {
      axios.post(`${REACT_APP_BACKEND_URL}/transaction/new`, {
        'provider': provider,
        'redirectUrl': redirectUrl
      })
      .then(res => {
        if (res.status===200) {
          window.open(res.data.accessUrl ,"_self")
        }
      })
      .catch(error => console.log(error))
    };

    return (
      <div className="home-body">
      <Nav status='Login'/>
      <div className="home-container login">
        <div className="frame">
          <img src={icon_user} alt="icon_user" className="icon_user"/>
          <h1>Login</h1>
          <p>Choose an authentication method to login</p>
          <div className="logo-contrainer">
            <div className="eid-btn" onClick={() => handleID('seBankID')} >
              <img src={icon_sv_bankid} alt="icon_sv_bankid" className="logo-se"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('noBankID')} >
              <img src={icon_no_bankid} alt="icon_no_bankid" className="logo-no"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('freja')} >
              <img src={icon_freja} alt="icon_freja" className="logo-freja"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('dkMitID')} >
              <img src={icon_mitid} alt="icon_mitid" className="logo-mitid"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('nlIDIN')} >
              <img src={icon_idin} alt="icon_idin" className="logo-idin"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('itsme')} >
              <img src={icon_itsme} alt="icon_itsme" className="logo-itsme"/>
            </div>
            <div className="eid-btn finland" onClick={() => handleID('fiTupas')} />
            <div className="eid-btn" onClick={() => handleID('smsOtp')} >
              <img src={icon_smsotp} alt="icon_smsotp" className="logo-smsotp"/>
            </div>
            <div className="eid-btn" onClick={() => handleID('verimi')} >
              <img src={icon_verimi} alt="icon_verimi" className="logo-verimi"/>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
};
export default Login;
