import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Redirect() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const success = queryParameters.get("success")

  useEffect(() => {
    sessionStorage.setItem(
       "success",
       success
    )
    navigate('/home');
  },[navigate, success]);
}

export default Redirect;
